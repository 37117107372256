body {
  position: relative;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}
* {
  box-sizing: border-box;
}
*:focus,
*:active {
  outline: 0;
  box-shadow: none !important;
}
button {
  cursor: pointer;
}
button:focus,
button:active {
  outline: none;
}
.header {
  background-color: #FFFFFF;
  height: 64px;
  box-shadow: 0px 3px 10px rgba(104, 104, 104, 0.07);
  padding: 0 62px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 5;
}
.header img {
  height: 46px;
  width: auto;
}
.header .nav-links {
  height: 100%;
  display: flex;
  margin-left: 100px;
}
.header .nav-links .nav-link {
  height: 100%;
  padding: 0 25px;
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 18px;
  color: #999999;
  position: relative;
  cursor: pointer;
}
.header .nav-links .nav-link .bar {
  position: absolute;
  background: #276DF7;
  border-radius: 5px 5px 0px 0px;
  width: 40px;
  height: 5px;
  bottom: 0;
  left: 50%;
  margin-left: -20px;
  display: none;
}
.header .nav-links .nav-link:hover {
  opacity: 0.8;
}
.header .nav-links .nav-link.active {
  color: #333333;
}
.header .nav-links .nav-link.active .bar {
  display: initial;
}
@media only screen and (max-width: 529px) {
  .header {
    padding: 0 5%;
  }
  .header .no-mobile {
    display: none;
  }
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background: rgba(0, 0, 0, 0.6);
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5%;
}
.modal .modal-main {
  position: fixed;
  background: white;
  width: auto;
  height: auto;
  border-radius: 3px;
  background: #FFFFFF;
  box-shadow: 0px 1px 3px rgba(63, 63, 68, 0.15), 0px 0px 0px rgba(63, 63, 68, 0.05);
}
@media only screen and (max-width: 529px) {
  .modal .modal-main {
    max-width: 90%;
  }
}
.modal .modal-main .close-icon {
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 24px;
  cursor: pointer;
  color: #C4C4C4;
}
.modal .modal-main .check {
  flex-grow: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 40px;
}
.modal .modal-main .check svg {
  width: 100px;
  height: 100px;
  display: block;
  margin: 40px auto 0;
  border: 15px solid #ADEAD7;
  border-radius: 50%;
  padding: 10px;
  animation: createBox 0.15s;
}
.modal .modal-main .check .reject {
  border: 15px solid #D37F7F;
}
.modal .modal-main .check .error {
  border: 15px solid #FEEC94;
}
.modal .modal-main .check .error .a {
  fill: #FFDB2B;
}
.modal .modal-main .check .path {
  stroke-dasharray: 1000;
  stroke-dashoffset: 0;
}
.modal .modal-main .check .path.circle {
  -webkit-animation: dash 0.9s ease-in-out;
  animation: dash 0.9s ease-in-out;
}
.modal .modal-main .check .path.line {
  stroke-dashoffset: 1000;
  -webkit-animation: dash 0.9s 0.35s ease-in-out forwards;
  animation: dash 0.9s 0.35s ease-in-out forwards;
}
.modal .modal-main .check .path.check {
  stroke-dashoffset: -100;
  -webkit-animation: dash-check 0.9s 0.35s ease-in-out forwards;
  animation: dash-check 0.9s 0.35s ease-in-out forwards;
}
.modal .modal-main .check p {
  text-align: center;
  margin: 20px 0 60px;
  font-size: 1.25em;
}
@-webkit-keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@-webkit-keyframes dash-check {
  0% {
    stroke-dashoffset: -100;
  }
  100% {
    stroke-dashoffset: 900;
  }
}
@keyframes dash-check {
  0% {
    stroke-dashoffset: -100;
  }
  100% {
    stroke-dashoffset: 900;
  }
}
@keyframes createBox {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}
@-webkit-keyframes createBox {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}
.modal .modal-main .check button {
  width: 147px;
  height: 36px;
  color: #ffffff;
  background: #6C63FF;
  border: 1px solid #5B60D3;
  box-sizing: border-box;
  box-shadow: 0px 1px 0px rgba(22, 29, 37, 0.1), inset 0px 1px 0px rgba(255, 255, 255, 0.06);
  border-radius: 3px;
  font-size: 14px;
  display: block;
  margin: auto;
}
.modal.display-block {
  display: flex;
}
.modal.display-none {
  display: none;
}
.success-div {
  min-width: 500px;
  max-width: 600px;
  background-color: #FFFFFF;
  padding: 54px 42px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: scroll;
  border-radius: 4px;
}
@media only screen and (max-width: 529px) {
  .success-div {
    padding: 40px 36px;
    min-width: initial;
  }
}
.success-div .success_icon {
  margin-bottom: 30px;
}
.success-div h4 {
  color: #001325;
  font-size: 22px;
  line-height: 28px;
  text-align: center;
  margin-bottom: 8px;
  font-weight: 500;
}
.success-div p {
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;
  text-align: center;
  color: rgba(0, 19, 37, 0.7);
  margin-bottom: 0;
}
.success-div button {
  background: #276DF7;
  border: 1px solid #276DF7;
  border-radius: 8px;
  color: #FFFFFF;
  font-size: 14px;
  width: 236px;
  height: 50px;
  cursor: pointer;
  margin-top: 30px;
}
.error-div {
  background-color: #FFFFFF;
  padding: 20px 42px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: scroll;
  border: 1px solid #D37F7F;
  border-radius: 4px;
}
.error-div h3 {
  color: #D37F7F;
  font-size: 18px;
  font-weight: normal;
}
.error-div p {
  font-size: 18px;
  color: #6C63FF;
  font-weight: bold;
  cursor: pointer;
  text-align: center;
  margin: 0;
}
.error-div i {
  font-size: 32px;
  color: #D37F7F;
}
.error-div button {
  background: #5D38DB;
  border-radius: 2px;
  color: #FFFFFF;
  font-size: 16px;
  height: 36px;
  font-weight: bold;
  cursor: pointer;
  padding-left: 20px;
  padding-right: 20px;
  margin: 10px 0;
}
.consent-div {
  width: 600px;
  padding: 30px 45px;
  max-height: 90vh;
  overflow-y: scroll;
}
@media only screen and (max-width: 529px) {
  .consent-div {
    width: 100%;
    padding: 30px 20px;
  }
}
.consent-div h4 {
  text-align: center;
  font-size: 18px;
  color: #276DF7;
  margin-bottom: 20px;
}
.consent-div p {
  margin-bottom: 10px;
  font-weight: normal;
  line-height: 150%;
  color: #212B36;
  font-size: 14px;
}
@media only screen and (max-width: 529px) {
  .consent-div p {
    text-align: justify;
  }
}
.consent-div .btn-div {
  display: flex;
  justify-content: center;
}
.consent-div button {
  margin-top: 10px;
  background: #5D38DB;
  border: 1px solid #5D38DB;
  height: 30px;
  color: #FFFFFF;
  cursor: pointer;
  border-radius: 2px;
  font-weight: 600;
}
.enrollment-form {
  background: #F4F7FA;
  position: relative;
}
@media only screen and (min-width: 949px) {
  .enrollment-form {
    padding-top: 126px;
  }
}
.enrollment-form .form-header {
  background: #FFFFFF;
  height: 76px;
  padding: 0 62px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
  box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.04);
}
.enrollment-form .form-header img {
  height: 56px;
  width: auto;
}
@media only screen and (min-width: 949px) {
  .enrollment-form .form-header {
    margin-bottom: 70px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
  }
}
@media only screen and (max-width: 949px) {
  .enrollment-form .form-header {
    height: 70px;
    padding: 0 20px;
  }
  .enrollment-form .form-header .nimr-logo img {
    height: 46px;
    width: auto;
  }
}
.enrollment-form .main-content {
  padding: 0 15%;
  padding-bottom: 100px;
}
@media only screen and (min-width: 949px) {
  .enrollment-form .main-content {
    display: grid;
    grid-template-columns: 275px 1.4fr;
    grid-column-gap: 32px;
  }
}
@media only screen and (min-width: 530px) and (max-width: 949px) {
  .enrollment-form .main-content {
    padding: 0 15%;
    padding-bottom: 150px;
  }
}
@media only screen and (max-width: 529px) {
  .enrollment-form .main-content {
    padding: 0 5%;
    padding-bottom: 150px;
  }
}
.enrollment-form .main-content .steps.desktop {
  display: none;
}
.enrollment-form .main-content .steps.mobile {
  display: none;
}
@media only screen and (min-width: 949px) {
  .enrollment-form .main-content .steps.desktop {
    display: initial;
    position: fixed;
  }
  .enrollment-form .main-content .steps.desktop .step {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(229, 229, 229, 0.2);
    border-radius: 8px;
    height: 74px;
    width: 275px;
    margin-bottom: 10px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #827F98;
    padding: 0 30px;
  }
  .enrollment-form .main-content .steps.desktop .step img {
    display: none;
  }
  .enrollment-form .main-content .steps.desktop .step.active {
    background: rgba(39, 109, 247, 0.1);
    color: #276DF7;
  }
  .enrollment-form .main-content .steps.desktop .step.past {
    color: #276DF7;
    cursor: pointer;
  }
  .enrollment-form .main-content .steps.desktop .step.past img {
    display: initial;
  }
}
.enrollment-form .main-content .steps-container {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 5;
}
@media only screen and (max-width: 949px) {
  .enrollment-form .main-content .steps.mobile {
    display: flex;
    align-items: center;
    background: #F4F7FA;
    box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.12);
    height: 100px;
    padding: 0 20px;
    overflow-x: scroll;
    scroll-behavior: smooth;
  }
  .enrollment-form .main-content .steps.mobile .step {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(229, 229, 229, 0.2);
    border-radius: 4px;
    height: 50px;
    min-width: 225px;
    margin-right: 10px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #827F98;
    padding: 0 15px;
  }
  .enrollment-form .main-content .steps.mobile .step img {
    display: none;
  }
  .enrollment-form .main-content .steps.mobile .step.active {
    background: rgba(39, 109, 247, 0.1);
    color: #276DF7;
  }
  .enrollment-form .main-content .steps.mobile .step.past {
    color: #276DF7;
  }
  .enrollment-form .main-content .steps.mobile .step.past img {
    display: initial;
  }
  .enrollment-form .main-content .steps.mobile .step-last:nth-child(5) {
    margin-right: 20px;
  }
}
.enrollment-form .main-content .form-title {
  display: none;
  font-size: 22px;
  color: #030303;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 15px;
}
@media only screen and (max-width: 529px) {
  .enrollment-form .main-content .form-title {
    font-size: 22px;
  }
}
.enrollment-form .main-content .description {
  display: none;
  font-weight: 450;
  font-size: 16px;
  line-height: 25px;
  color: rgba(3, 3, 3, 0.7);
  margin-bottom: 30px;
}
.enrollment-form .main-content .description span {
  color: #B83422;
  font-weight: 600;
}
@media only screen and (min-width: 949px) {
  .enrollment-form .main-content .form-title.desktop,
  .enrollment-form .main-content .description.desktop {
    display: block;
  }
}
@media only screen and (max-width: 949px) {
  .enrollment-form .main-content .form-title.mobile,
  .enrollment-form .main-content .description.mobile {
    display: block;
  }
}
.enrollment-form .main-content .form-sections .section-title {
  display: none;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: #030303;
  margin-bottom: 16px;
}
@media only screen and (min-width: 949px) {
  .enrollment-form .main-content .form-sections .section-title.desktop {
    display: block;
  }
}
@media only screen and (max-width: 949px) {
  .enrollment-form .main-content .form-sections .section-title.mobile {
    display: block;
  }
}
.enrollment-form .main-content .form-sections .section-description {
  display: none;
  margin-top: -10px;
  margin-bottom: 16px;
  font-size: 16px;
  line-height: 24px;
  color: rgba(3, 3, 3, 0.5);
}
@media only screen and (min-width: 949px) {
  .enrollment-form .main-content .form-sections .section-description.desktop {
    display: block;
  }
}
@media only screen and (max-width: 949px) {
  .enrollment-form .main-content .form-sections .section-description.mobile {
    display: block;
  }
}
.enrollment-form .main-content .form-sections .section-content {
  background: #FFFFFF;
  border-radius: 8px;
  padding: 30px 20px 5px;
  margin-bottom: 50px;
  box-shadow: 0px 4px 4px rgba(229, 229, 229, 0.2);
}
@media only screen and (min-width: 949px) {
  .enrollment-form .main-content .form-sections .section-content {
    padding: 50px 20%;
  }
}
.enrollment-form .main-content .form-sections .section-content label {
  color: #827F98;
  font-size: 14px;
  position: relative;
}
.enrollment-form .main-content .form-sections .section-content label .input-options {
  display: grid;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 30px;
}
.enrollment-form .main-content .form-sections .section-content label .input-options .option {
  background: #F6F6F6;
  border: 1px solid #DBDAE3;
  box-sizing: border-box;
  border-radius: 4px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 450;
  font-size: 16px;
  line-height: 20px;
  color: #828282;
  cursor: pointer;
}
.enrollment-form .main-content .form-sections .section-content label .input-options .option.selected {
  background: rgba(39, 109, 247, 0.1);
  border: 1px solid #276DF7;
  color: #276DF7;
}
.enrollment-form .main-content .form-sections .section-content label .input-options.two {
  grid-template-columns: 1fr 1fr;
}
.enrollment-form .main-content .form-sections .section-content label .input-options.three {
  grid-template-columns: 1fr 1fr 1fr;
}
.enrollment-form .main-content .form-sections .section-content label .input-options.four {
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
.enrollment-form .main-content .form-sections .section-content label .input-options.three-mobile {
  grid-template-columns: 1fr 1fr 1fr;
}
@media only screen and (max-width: 529px) {
  .enrollment-form .main-content .form-sections .section-content label .input-options.three-mobile {
    grid-template-columns: 1fr;
  }
}
.enrollment-form .main-content .form-sections .section-content label input,
.enrollment-form .main-content .form-sections .section-content label input[type=date],
.enrollment-form .main-content .form-sections .section-content label select,
.enrollment-form .main-content .form-sections .section-content label .input {
  width: 100%;
  height: 50px;
  background: #F6F6F6;
  border: 1px solid #DBDAE3;
  box-sizing: border-box;
  border-radius: 4px;
  margin-top: 10px;
  margin-bottom: 30px;
  font-weight: normal;
  font-size: 16px;
  color: #333333;
  padding: 0 18px;
}
.enrollment-form .main-content .form-sections .section-content label .date-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.enrollment-form .main-content .form-sections .section-content label input:focus,
.enrollment-form .main-content .form-sections .section-content label input:active,
.enrollment-form .main-content .form-sections .section-content label select:focus,
.enrollment-form .main-content .form-sections .section-content label select:active {
  outline: 0;
  border: 1px solid #276DF7;
}
.enrollment-form .main-content .form-sections .section-content label textarea {
  width: 100%;
  background: #F6F6F6;
  border: 1px solid #DBDAE3;
  box-sizing: border-box;
  border-radius: 4px;
  margin-top: 10px;
  margin-bottom: 30px;
  font-weight: normal;
  font-size: 16px;
  color: #333333;
  padding: 15px 18px;
}
.enrollment-form .main-content .form-sections .section-content label textarea:focus {
  outline: 0;
  border: 1px solid #276DF7;
}
.enrollment-form .main-content .form-sections .section-content label textarea:disabled {
  opacity: 0.5;
}
.enrollment-form .main-content .form-sections .section-content label .autocomplete {
  position: absolute;
  top: 75px;
  background-color: #FFFFFF;
  padding: 10px;
  left: 0;
  right: 0;
  box-shadow: 0px 0px 10px rgba(219, 218, 227, 0.4);
  border-radius: 4px;
  z-index: 2;
}
.enrollment-form .main-content .form-sections .section-content label .autocomplete .autocomplete-item {
  font-size: 16px;
  color: rgba(113, 113, 113, 0.94);
  text-align: left;
  padding: 10px 0;
  border-top: 1px solid rgba(219, 218, 227, 0.49);
  background-color: #FFFFFF;
  cursor: pointer;
}
.enrollment-form .main-content .form-sections .section-content label .autocomplete .autocomplete-item i {
  margin-right: 12px;
  color: rgba(113, 113, 113, 0.94);
}
.enrollment-form .main-content .form-sections .section-content label .autocomplete .autocomplete-item:first-child {
  border-top: initial;
}
.enrollment-form .main-content .form-sections .section-content label .autocomplete .bottom {
  height: 15px;
  margin-top: 10px;
}
.enrollment-form .main-content .form-sections .section-content label .autocomplete .bottom img {
  position: absolute;
  height: 15px;
  width: auto;
  right: 10px;
}
.enrollment-form .main-content .form-sections .section-content label .intl-tel-input {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 30px;
}
.enrollment-form .main-content .form-sections .section-content label .intl-tel-input input {
  padding-left: 100px;
}
.enrollment-form .main-content .form-sections .section-content label .intl-tel-input .flag-container {
  padding-left: 10px;
}
.enrollment-form .main-content .form-sections .section-content label .intl-tel-input .flag-container .iti-flag {
  height: 10px;
  margin-top: 70%;
  margin-right: 5px;
  vertical-align: middle;
}
.enrollment-form .main-content .form-sections .section-content label .intl-tel-input .selected-dial-code {
  padding-left: 0;
  font-size: 16px;
}
.enrollment-form .main-content .form-sections .section-content label .intl-tel-input .arrow {
  font-size: 12px;
}
@media only screen and (max-width: 529px) {
  .enrollment-form .main-content .form-sections .section-content label .intl-tel-input .arrow {
    font-size: 8px;
  }
}
.enrollment-form .main-content .form-sections .section-content label .intl-tel-input.allow-dropdown.separate-dial-code .selected-flag {
  background-color: transparent;
}
.enrollment-form .main-content .form-sections .section-content label .intl-tel-input.allow-dropdown .flag-container:hover .selected-flag {
  background-color: transparent;
}
.enrollment-form .main-content .form-sections .section-content label .options-list {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 10px;
  margin-top: 10px;
  margin-bottom: -10px;
}
.enrollment-form .main-content .form-sections .section-content label .options-list .option {
  background: rgba(246, 246, 246, 0.1);
  border: 1px solid #DBDAE3;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  align-items: center;
  height: 50px;
  padding: 0 18px;
  font-size: 16px;
  color: #828282;
  margin-bottom: 8px;
}
.enrollment-form .main-content .form-sections .section-content label .options-list .option p {
  margin-right: 10px;
}
.enrollment-form .main-content .form-sections .section-content label .options-list .option .check {
  margin-left: auto;
  background: #F6F6F6;
  border: 1px solid rgba(130, 130, 130, 0.4);
  box-sizing: border-box;
  width: 18px;
  height: 18px;
  border-radius: 50%;
}
.enrollment-form .main-content .form-sections .section-content label .options-list .option .check i {
  display: none;
}
.enrollment-form .main-content .form-sections .section-content label .options-list .option.selected {
  background: rgba(39, 109, 247, 0.1);
  border: 1px solid #276DF7;
  color: #276DF7;
  font-weight: 450;
}
.enrollment-form .main-content .form-sections .section-content label .options-list .option.selected .check {
  border: none;
}
.enrollment-form .main-content .form-sections .section-content label .options-list .option.selected .check i {
  display: initial;
  font-size: 20px;
  color: #276DF7;
}
.enrollment-form .main-content .form-sections .section-content label .options-list .option.disabled {
  opacity: 0.5;
}
.enrollment-form .main-content .form-sections .section-content label .add-contact-btn {
  height: 50px;
  width: 100%;
  background: #F8F8F8;
  border: 1px dashed #686868;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #828282;
  padding: 0 18px;
  margin-top: 10px;
  margin-bottom: 30px;
}
.enrollment-form .main-content .form-sections .section-content label .add-contact-btn i {
  margin-left: auto;
  font-size: 18px;
  color: #7B7B7F;
}
.enrollment-form .main-content .form-sections .section-content label .add-contact-btn i.cancel {
  color: #DA251C;
}
.enrollment-form .main-content .form-sections .section-content label .contact-list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 10px;
}
@media only screen and (max-width: 529px) {
  .enrollment-form .main-content .form-sections .section-content label .contact-list {
    grid-template-columns: 1fr;
  }
}
.enrollment-form .main-content .form-sections .section-content label .contact-list .contact {
  background: rgba(39, 109, 247, 0.2);
  border-radius: 4px;
  height: 50px;
  display: flex;
  align-items: center;
  color: #276DF7;
  font-size: 16px;
  margin-top: 10px;
  padding: 0 18px;
}
.enrollment-form .main-content .form-sections .section-content label .contact-list .contact i {
  margin-left: auto;
}
.enrollment-form .main-content .form-sections .section-content label span.consent-link {
  color: #276DF7;
  font-weight: 500;
}
.enrollment-form .main-content .form-sections .section-content .dob-label {
  width: 100%;
  display: block;
}
.enrollment-form .main-content .form-sections .section-content .dob-label .warning {
  bottom: 0;
  font-size: 12px;
  margin-bottom: 5px;
  margin-top: -25px;
  font-weight: 500;
  color: #FFC107;
}
.enrollment-form .main-content .form-sections .section-content .dob-label .warning-input {
  border-color: #FFC107;
}
@media only screen and (max-width: 949px) {
  .enrollment-form .main-content .form-sections .section-content .dob-label.desktop {
    display: none;
  }
}
.enrollment-form .main-content .form-sections .section-content .dob-label.mobile {
  display: none;
}
@media only screen and (max-width: 949px) {
  .enrollment-form .main-content .form-sections .section-content .dob-label.mobile {
    display: block;
  }
}
.enrollment-form .main-content .form-sections .section-content .dob-label.mobile .warning {
  bottom: 0;
  font-size: 12px;
  margin-bottom: 5px;
  margin-top: -25px;
  font-weight: 500;
  color: #FFC107;
}
.enrollment-form .main-content .form-sections .section-content .dob-label.mobile .warning-input {
  border-color: #FFC107;
}
.enrollment-form .main-content .form-sections .section-content .contact-details .save-contact-btn {
  width: 113px;
  height: 45px;
  background: #276DF7;
  border: 1px solid #276DF7;
  border-radius: 7px;
  font-weight: 500;
  font-size: 15px;
  color: #FFFFFF;
  margin-bottom: 30px;
}
.enrollment-form .main-content .form-sections .section-content label.error {
  color: #9c2b2e;
}
.enrollment-form .main-content .form-sections .section-content label.error input,
.enrollment-form .main-content .form-sections .section-content label.error select,
.enrollment-form .main-content .form-sections .section-content label.error textarea,
.enrollment-form .main-content .form-sections .section-content label.error .input {
  border: 1px solid #9c2b2e;
}
.enrollment-form .main-content .form-sections .section-content .error-text {
  margin-bottom: -15px;
  margin-top: -15px;
}
.enrollment-form .main-content .form-sections .section-content .loader {
  color: #276DF7;
  font-size: 28px;
  margin-bottom: -15px;
  margin-top: 15px;
  text-align: center;
  display: block;
  width: 100%;
}
.enrollment-form .main-content .form-sections .section-content .button-section {
  display: flex;
  padding: 30px 0;
}
.enrollment-form .main-content .form-sections .section-content .button-section button {
  width: 142px;
  height: 55px;
  background: #F3F3F4;
  border: 1px solid #F3F3F4;
  border-radius: 6px;
  font-weight: 500;
  font-size: 16px;
  color: #161531;
}
@media only screen and (max-width: 529px) {
  .enrollment-form .main-content .form-sections .section-content .button-section button {
    width: 45%;
  }
}
.enrollment-form .main-content .form-sections .section-content .button-section button.right-button {
  background: #276DF7;
  border: 1px solid #276DF7;
  font-weight: 500;
  font-size: 16px;
  color: #FFFFFF;
  margin-left: auto;
}
.enrollment-form .main-content .consent {
  font-size: 15px;
  line-height: 24px;
  color: rgba(255, 255, 255, 0.8);
  display: flex;
  margin-bottom: 50px;
}
.enrollment-form .main-content .consent span {
  color: #276DF7;
}
.enrollment-form .main-content .consent .check {
  height: 20px;
  width: 20px;
  min-height: 20px;
  min-width: 20px;
  border-radius: 2px;
  border: 0.75px solid #DBDAE3;
  margin-right: 10px;
  margin-top: 5px;
}
.enrollment-form .main-content .consent .check.checked {
  border: 0.75px solid #5D38DB;
  background-color: #5D38DB;
}
.enrollment-form .main-content .btn-div {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.enrollment-form .main-content .btn-div button {
  background: #276DF7;
  border: 1px solid #276DF7;
  border-radius: 8px;
  width: 265px;
  height: 55px;
  font-weight: 500;
  font-size: 16px;
  color: #FFFFFF;
}
.enrollment-form .main-content .btn-div .loader {
  color: #276DF7;
  font-size: 24px;
  margin-bottom: 10px;
  margin-top: -34px;
}
.enrollment-form .main-content .logo-div {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.enrollment-form .main-content .logo-div p {
  font-size: 14px;
  line-height: 18px;
  color: rgba(3, 3, 3, 0.7);
  margin-right: 5px;
}
.enrollment-form .main-content .logo-div img {
  height: 30px;
  width: auto;
}
@media only screen and (min-width: 949px) {
  .enrollment-form .main-content .logo-div.mobile {
    display: none;
  }
}
.enrollment-form .main-content .error-text {
  background-color: #9c2b2e;
  border: 1px solid #e84e4f;
  color: #FFFFFF;
  text-align: center;
  border-radius: 4px;
  width: 100%;
  font-size: 12px;
  margin-bottom: 10px;
  margin-top: -34px;
  padding: 5px 15px;
}
.enrollment-form .main-content .error-text i {
  margin-right: 10px;
}
.datepicker-modal {
  position: fixed !important;
  align-items: flex-end !important;
}
.datepicker {
  position: initial !important;
}
.datepicker.default .datepicker-header {
  color: #276DF7 !important;
}
.datepicker .datepicker-header {
  padding: 0.5em;
  min-height: 2em;
  line-height: 2em;
  font-size: 1.2em;
  font-weight: 600;
  color: #276DF7 !important;
}
.datepicker .datepicker-caption {
  padding: 0 0.25em;
}
.datepicker .datepicker-caption-item {
  height: auto;
  line-height: 1.2em;
  font-size: 1.2em;
}
.datepicker.default .datepicker-navbar-btn:first-child {
  color: #276DF7;
  font-weight: 600;
}
.datepicker.default .datepicker-navbar-btn:last-child {
  color: #827F98;
}
.datepicker .datepicker-navbar-btn {
  height: 2.5em;
  line-height: 2.5em;
  float: right;
  padding: 0 1em;
  cursor: pointer;
  font-size: 1.125em;
}
.datepicker.default .datepicker-wheel {
  border-top: 1px solid #276DF7 !important;
  border-bottom: 1px solid #276DF7 !important;
}
.datepicker .datepicker-scroll > li {
  opacity: 0;
}
.datepicker .datepicker-scroll > li:nth-child(4) {
  opacity: 0.2;
}
.datepicker .datepicker-scroll > li:nth-child(5) {
  opacity: 0.6;
}
.datepicker .datepicker-scroll > li:nth-child(6) {
  opacity: 1;
}
.datepicker .datepicker-scroll > li:nth-child(7) {
  opacity: 0.6;
}
.datepicker .datepicker-scroll > li:nth-child(8) {
  opacity: 0.2;
}
.datepicker .datepicker-navbar {
  padding: 0 0.5em 2em 0.5em;
  overflow: hidden;
}
.dashboard {
  padding-bottom: 40px;
  background: #F5F6FA;
  padding-top: 64px;
  min-height: 100vh;
}
.dashboard .waves {
  margin-bottom: 40px;
  width: 100%;
}
.dashboard .waves img {
  width: 100%;
}
.dashboard .search-section {
  margin: 40px 5%;
  margin-bottom: 20px;
  background: #FFFFFF;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 0;
}
@media only screen and (max-width: 529px) {
  .dashboard .search-section {
    padding: 20px 10px;
  }
}
.dashboard .search-section h4 {
  margin-top: 0;
  margin-bottom: 15px;
}
.dashboard .search-section .input-div {
  width: 62%;
  height: 45px;
  position: relative;
}
@media only screen and (max-width: 529px) {
  .dashboard .search-section .input-div {
    width: 100%;
  }
}
.dashboard .search-section .input-div input {
  height: 100%;
  width: 100%;
  background: #FEFEFE;
  border: 0.75px solid #DBDAE3;
  border-radius: 3px;
  box-sizing: border-box;
  padding-left: 18px;
  padding-right: 32px;
  font-size: 16px;
}
@media only screen and (max-width: 529px) {
  .dashboard .search-section .input-div input {
    font-size: 14px;
  }
}
.dashboard .search-section .input-div input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #DBDAE3;
}
.dashboard .search-section .input-div input::-moz-placeholder {
  /* Firefox 19+ */
  color: #DBDAE3;
}
.dashboard .search-section .input-div input:-ms-input-placeholder {
  /* IE 10+ */
  color: #DBDAE3;
}
.dashboard .search-section .input-div input:-moz-placeholder {
  /* Firefox 18- */
  color: #DBDAE3;
}
.dashboard .search-section .input-div input:focus,
.dashboard .search-section .input-div input:active {
  outline: none;
}
.dashboard .search-section .input-div i {
  position: absolute;
  top: 10px;
  right: 15px;
  color: #DADADA;
}
.dashboard .case-list {
  margin: 40px 5%;
}
.appointments {
  padding: 140px 12%;
  padding-bottom: 40px;
  background: #F5F6FA;
  min-height: 100vh;
}
@media only screen and (max-width: 949px) {
  .appointments {
    padding: 120px 3%;
  }
}
.appointments .metrics {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 36px;
  margin-bottom: 50px;
}
@media only screen and (max-width: 949px) {
  .appointments .metrics {
    grid-column-gap: 20px;
    margin-bottom: 30px;
  }
}
.appointments .metrics .metric {
  height: 118px;
  background: #FFFFFF;
  border-radius: 10px;
  padding: 0 30px;
  display: flex;
  align-items: center;
}
.appointments .metrics .metric img {
  margin-right: 20px;
}
.appointments .metrics .metric h4 {
  font-weight: 500;
  font-size: 26px;
  line-height: 33px;
  color: rgba(22, 21, 49, 0.8);
}
.appointments .metrics .metric h5 {
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #4F4F4F;
}
@media only screen and (max-width: 949px) {
  .appointments .metrics .metric {
    height: 80px;
    padding: 0 15px;
  }
  .appointments .metrics .metric img {
    height: 40px;
    width: 40px;
  }
  .appointments .metrics .metric h4 {
    font-size: 18px;
    line-height: 26px;
  }
  .appointments .metrics .metric h5 {
    font-size: 14px;
    line-height: 18px;
  }
}
.appointments .appointments-title {
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #252733;
  margin-bottom: 30px;
}
.appointments .search-download {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}
.appointments .search-download .search-bar {
  position: relative;
}
.appointments .search-download .search-bar input {
  width: 435px;
  height: 45px;
  background: linear-gradient(180deg, #FFFFFF 0.86%, #FEFEFE 100%);
  border: 1px solid rgba(37, 39, 51, 0.1);
  box-sizing: border-box;
  border-radius: 5px;
  padding: 0 44px;
}
.appointments .search-download .search-bar input:active,
.appointments .search-download .search-bar input:focus {
  outline: none;
}
.appointments .search-download .search-bar input::placeholder {
  color: rgba(37, 39, 51, 0.3);
}
.appointments .search-download .search-bar img {
  position: absolute;
  left: 20px;
  top: 50%;
  margin-top: -7.5px;
}
.appointments .search-download button,
.appointments .search-download .csv-button {
  width: 173px;
  height: 45px;
  background: #276DF7;
  border: 1px solid #276DF7;
  border-radius: 5px;
  font-weight: 500;
  font-size: 15px;
  color: #FFFFFF;
}
.appointments .search-download .csv-button {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}
.appointments .invitees-list {
  background: #FFFFFF;
  border: 1px solid rgba(223, 224, 235, 0.7);
  box-sizing: border-box;
  border-radius: 8px;
}
.appointments .invitees-list .top {
  padding: 28px 50px;
  display: flex;
  align-items: center;
  position: relative;
}
.appointments .invitees-list .top h4 {
  font-weight: bold;
  font-size: 19px;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: #252733;
}
.appointments .invitees-list .top button.date-btn {
  background: transparent;
  border: 1px solid #276DF7;
  color: #276DF7;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 0 15px;
  height: 35px;
  margin-left: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.appointments .invitees-list .top button.date-btn img {
  margin-left: 14px;
}
.appointments .invitees-list .top .react-datepicker-wrapper {
  height: 0;
  position: absolute;
  right: 50px;
  top: 60px;
}
.appointments .invitees-list .top .react-datepicker-wrapper .react-datepicker__input-container input {
  visibility: hidden;
}
@media only screen and (max-width: 949px) {
  .appointments .invitees-list .top {
    padding: 16px 20px;
  }
  .appointments .invitees-list .top h4 {
    font-size: 16px;
  }
}
.appointments .invitees-list table {
  width: 100%;
  border-collapse: collapse;
}
.appointments .invitees-list table th {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.2px;
  color: #9FA2B4;
  padding: 12px 0;
  border-bottom: 1px solid #DFE0EB;
  text-align: left;
}
@media only screen and (max-width: 949px) {
  .appointments .invitees-list table th {
    padding: 12px 5px;
  }
}
.appointments .invitees-list table th:first-child {
  padding-left: 50px;
}
@media only screen and (max-width: 949px) {
  .appointments .invitees-list table th:first-child {
    padding-left: 20px;
  }
}
.appointments .invitees-list table td {
  padding: 26px 0;
  font-weight: 450;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #252733;
  border-bottom: 1px solid #DFE0EB;
}
@media only screen and (max-width: 949px) {
  .appointments .invitees-list table td {
    padding: 16px 5px;
  }
}
.appointments .invitees-list table td .date h4 {
  font-weight: 450;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #252733;
}
.appointments .invitees-list table td .date h5 {
  font-weight: 450;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.1px;
  color: #C5C7CD;
}
.appointments .invitees-list table td:first-child {
  padding-left: 50px;
}
@media only screen and (max-width: 949px) {
  .appointments .invitees-list table td:first-child {
    padding-left: 20px;
  }
}
@media only screen and (max-width: 949px) {
  .appointments .invitees-list table td:last-child {
    padding-right: 10px;
  }
}
.appointments .invitees-list table tr:last-child td {
  border-bottom: 0;
}
.case-list {
  background: #FFFFFF;
  border-radius: 4px;
}
.case-list .title {
  padding: 0 40px;
  height: 72px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 0.5px solid rgba(137, 134, 142, 0.22);
}
@media only screen and (max-width: 529px) {
  .case-list .title {
    height: 46px;
    padding: 0 20px;
  }
}
.case-list .title h4 {
  font-size: 18px;
  letter-spacing: 0.02em;
  color: #3A3643;
}
@media only screen and (max-width: 529px) {
  .case-list .title h4 {
    font-size: 14px;
  }
}
.case-list .list {
  padding: 0 40px;
}
@media only screen and (max-width: 529px) {
  .case-list .list {
    padding: 0 20px;
  }
}
.case-list .list table {
  width: 100%;
  border-spacing: 0;
}
.case-list .list table tr {
  width: 100%;
}
.case-list .list table tr th {
  padding: 20px 0 18px 0;
  text-align: left;
  border-bottom: 1px solid rgba(209, 216, 245, 0.6);
}
.case-list .list table tr th div {
  font-size: 14px;
  color: #7F7F7F;
  font-weight: initial;
  padding: 0px 25px 0px 10px;
  background: #F5F6FA;
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
  height: 30px;
  box-sizing: border-box;
  cursor: pointer;
}
.case-list .list table tr th div i {
  margin-right: -15px;
  margin-left: 15px;
}
.case-list .list table tr th div.first {
  cursor: initial;
}
.case-list .list table tr th .sort-div {
  height: 30px;
}
.case-list .list table tr th .sort-div .icon-div {
  margin-right: -15px;
  margin-left: 15px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.case-list .list table tr th .sort-div .icon-div i {
  margin: 0;
  opacity: 0.5;
}
.case-list .list table tr th .sort-div .icon-div i.active {
  opacity: initial;
}
.case-list .list table tr th select {
  font-size: 14px;
  color: #7F7F7F;
  background: #F5F6FA;
  border-radius: 4px;
  height: 30px;
  border: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  cursor: pointer;
}
.case-list .list table tr th select:focus,
.case-list .list table tr th select:active {
  outline: none;
}
.case-list .list table tr th select::-ms-expand {
  display: none;
}
.case-list .list table tr td {
  border-bottom: 1px solid rgba(209, 216, 245, 0.6);
  font-size: 16px;
  color: #89868E;
  cursor: pointer;
  position: relative;
}
.case-list .list table tr td i {
  margin-right: 10px;
}
.case-list .list table tr td .table-item {
  padding: 18px 0 16px 0;
}
.case-list .list table tr td .view-button {
  background: #6C63FF;
  box-shadow: 2px 2px 5px rgba(128, 152, 213, 0.15);
  border-radius: 4px;
  color: #FFFFFF;
  font-size: 14px;
  padding-right: 20px;
  padding-left: 20px;
  height: 35px;
  font-weight: bold;
  border: 1px solid #6C63FF;
}
.case-list .list table tr td span.yes {
  color: #ffffff;
  padding: 3px 5px;
  border-radius: 2px;
  background: #14DA9E;
  display: inline-block;
}
.case-list .list table tr td span.no {
  color: #ffffff;
  padding: 3px 5px;
  border-radius: 2px;
  background: #E5454C;
  display: inline-block;
}
.case-list .list table tr td .info-popup {
  display: none;
  position: absolute;
  left: 50px;
  top: 20px;
  background: #FFFFFF;
  box-shadow: 0px 0px 6px rgba(28, 28, 28, 0.2);
  border-radius: 4px;
  z-index: 1;
  min-width: 550px;
  width: auto;
}
.case-list .list table tr td .info-popup .details {
  padding: 26px 35px;
  display: flex;
}
.case-list .list table tr td .info-popup .details p {
  margin-bottom: 10px;
  margin-right: 10px;
}
.case-list .list table tr td .info-popup .details p span {
  font-weight: 500;
}
.case-list .list table tr td .info-popup .details p:last-child {
  margin-bottom: 0;
}
.case-list .list table tr td .info-popup .details button {
  margin-left: auto;
}
.case-list .list table tr td .info-popup .details button.send-invite {
  background: #19AC55;
  border: 1px solid #19AC55;
  color: #FFFFFF;
  width: 165px;
  padding: 0 5px;
  height: 35px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: bold;
}
.case-list .list table tr td .info-popup .details .invite-sent {
  margin-left: auto;
  background: #19AC55;
  border: 1px solid #19AC55;
  color: #FFFFFF;
  font-size: 14px;
  height: 35px;
  padding: 0 15px;
  border-radius: 4px;
  display: flex;
  align-items: center;
}
.case-list .list table tr td .info-popup .invite-modal {
  padding: 25px 35px;
}
.case-list .list table tr td .info-popup .invite-modal h4 {
  font-weight: bold;
  font-size: 16px;
}
.case-list .list table tr td .info-popup .invite-modal h5 {
  font-size: 12px;
}
.case-list .list table tr td .info-popup .invite-modal .invite-section {
  grid-column-gap: 20px;
  margin-top: 20px;
}
.case-list .list table tr td .info-popup .invite-modal .invite-section .time-section {
  padding: 10px 18px;
}
.case-list .list table tr td .info-popup .invite-modal .invite-section .time-section p {
  margin-bottom: 10px;
  font-size: 14px;
}
.case-list .list table tr td .info-popup .invite-modal .invite-section .time-section .time-slots {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
}
.case-list .list table tr td .info-popup .invite-modal .invite-section .time-section .time-slots .slot {
  height: 30px;
  width: 100px;
  font-size: 12px;
  font-weight: 600;
  border-radius: 4px;
}
.case-list .list table tr td .info-popup .invite-modal .invite-section .time-section .time-slots .slot.selected {
  background: #2979FF;
  border: 1px solid #2979FF;
  color: #FFFFFF;
}
.case-list .list table tr td .info-popup .invite-modal .invite-section .time-section .time-slots .slot.selected:hover {
  background: #2979FF;
}
.case-list .list table tr td .info-popup .invite-modal .invite-section .time-section .time-slots .slot:hover {
  background: rgba(240, 240, 241, 0.5);
}
.case-list .list table tr td .info-popup .invite-modal .invite-section .date-selected .date-input-div {
  padding: 10px 20px;
}
.case-list .list table tr td .info-popup .invite-modal .invite-section .date-selected .date-input-div p {
  font-size: 14px;
  margin-bottom: 5px;
}
.case-list .list table tr td .info-popup .invite-modal .invite-section .date-selected .date-input-div .date-input {
  height: 35px;
  font-size: 14px;
  padding: 0 15px;
}
.case-list .list table tr td .info-popup .invite-modal .invite-section .date-selected .appointment-details {
  padding: 10px 18px;
  background: #F6F7FB;
  border-radius: 4px;
}
.case-list .list table tr td .info-popup .invite-modal .invite-section .date-selected .appointment-details .invite-no {
  font-size: 10px;
  border-radius: 50px;
  padding: 2px 6px;
}
.case-list .list table tr td .info-popup .invite-modal .invite-section .date-selected .appointment-details p {
  font-size: 12px;
  line-height: 150%;
}
.case-list .list table tr td .info-popup .invite-modal .invite-section .date-selected .appointment-details h4 {
  font-size: 14px;
  line-height: 150%;
  margin-bottom: 15px;
}
.case-list .list table tr td .info-popup .invite-modal .invite-section .date-selected .appointment-details .error-text {
  font-size: 10px;
  margin-top: -18px;
}
.case-list .list table tr td .info-popup .invite-modal .invite-section .date-selected .appointment-details .button-div {
  display: flex;
}
.case-list .list table tr td .info-popup .invite-modal .invite-section .date-selected .appointment-details .button-div button {
  height: 35px;
  width: 140px;
  font-size: 12px;
}
.case-list .list table tr td:hover .info-popup {
  display: block;
}
.case-list .list table tr td:last-child {
  text-align: right;
  cursor: initial;
}
.case-list .list table tr .loader {
  border-bottom: 0;
  text-align: center !important;
}
.case-list .list table tr .loader i {
  color: #6C63FF;
  font-size: 46px;
}
@media only screen and (max-width: 529px) {
  .case-list .list table {
    display: inline-block;
  }
  .case-list .list table tr {
    display: flex;
    flex-wrap: wrap;
  }
  .case-list .list table tr td {
    display: block;
    flex: 1;
    padding: 10px 5px;
  }
  .case-list .list table tr td:nth-child(3) {
    display: none;
  }
  .case-list .list table tr:first-child {
    display: none;
  }
}
.case-list .list .btn-div {
  display: flex;
  justify-content: center;
  padding: 31px;
  box-sizing: border-box;
}
.case-list .list .btn-div button {
  background: #6C63FF;
  box-shadow: 2px 2px 5px rgba(128, 152, 213, 0.15);
  border-radius: 4px;
  color: #FFFFFF;
  font-size: 14px;
  width: 144px;
  height: 35px;
  font-weight: bold;
}
.case-list .list .pagination-div {
  display: flex;
  justify-content: center;
  padding: 31px;
  box-sizing: border-box;
  position: relative;
}
.case-list .list .pagination-div .entries {
  position: absolute;
  height: 42px;
  display: flex;
  align-items: center;
  padding: 0 15px;
  font-size: 16px;
  color: #7F7F7F;
  border: 1px solid rgba(209, 216, 245, 0.6);
  border-radius: 2px;
  box-sizing: border-box;
  left: 0;
}
.case-list .list .pagination-div .entries select {
  font-size: 16px;
  color: #89868E;
  background: transparent;
  border: 0;
  padding: 0;
  margin-top: 3px;
}
.case-list .list .pagination-div .entries select:focus,
.case-list .list .pagination-div .entries select:active {
  outline: none;
}
.case-list .list .pagination-div .pagination {
  display: flex;
  align-items: center;
}
.case-list .list .pagination-div .pagination button {
  width: 86px;
  height: 42px;
  font-size: 16px;
  color: #6C62E3;
  border: 0.75px solid #E8EBF2;
  border-radius: 4px;
  background: #F5F6FA;
  font-weight: bold;
}
.case-list .list .pagination-div .pagination button:first-child {
  margin-right: 7px;
}
.case-list .list .pagination-div .pagination button:disabled {
  opacity: 0.5;
}
.case-list .list .pagination-div .pagination input,
.case-list .list .pagination-div .pagination select {
  width: 55px;
  height: 42px;
  text-align: center;
  box-sizing: border-box;
  border-radius: 4px;
  border: 0.75px solid #E8EBF2;
  font-size: 16px;
  color: #6C63FF;
  margin-right: 7px;
}
.case-list .list .pagination-div .pagination p {
  font-size: 16px;
  cursor: default;
  margin-right: 7px;
}
.case-list .list .pagination-div .pagination div {
  width: 55px;
  height: 42px;
  background: #6C63FF;
  box-shadow: -10px 9px 21px rgba(128, 152, 213, 0.074983);
  border-radius: 4px;
  color: #FFFFFF;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: default;
  margin-right: 7px;
}
@media only screen and (min-width: 530px) and (max-width: 949px) {
  .case-list .list table {
    overflow-x: auto;
    white-space: nowrap;
  }
  .case-list .list table tr td {
    padding: 15px 0;
    padding-right: 15px;
  }
  .case-list .list .pagination-div {
    justify-content: space-between;
    padding-left: 0;
    padding-right: 0;
  }
  .case-list .list .pagination-div .entries {
    position: initial;
  }
}
.case-list.empty {
  padding: 20px;
}
.case-list.empty h4 {
  text-align: center;
  font-size: 16px;
  font-weight: normal;
  color: #333333;
}
.enrollee-profile {
  width: 100%;
  box-sizing: border-box;
  background: #F5F6FA;
  margin-top: 64px;
  padding: 10%;
  padding-top: 40px;
}
@media only screen and (min-width: 530px) and (max-width: 949px) {
  .enrollee-profile {
    padding: 10% 5%;
  }
}
@media only screen and (max-width: 529px) {
  .enrollee-profile {
    margin-top: 64px;
    padding: 5% 2%;
  }
}
.enrollee-profile .back {
  margin-bottom: 25px;
  font-size: 16px;
  color: #333333;
  cursor: pointer;
  display: inline-block;
}
.enrollee-profile .back i {
  margin-right: 10px;
}
.enrollee-profile .enrollee-top {
  background: #FFFFFF;
  border-radius: 5px;
  padding: 48px 52px;
  margin-bottom: 37px;
}
@media only screen and (min-width: 530px) and (max-width: 949px) {
  .enrollee-profile .enrollee-top {
    padding: 24px 30px;
  }
}
.enrollee-profile .enrollee-top .name-div {
  display: flex;
  align-items: center;
  margin-bottom: 35px;
}
@media only screen and (min-width: 530px) and (max-width: 949px) {
  .enrollee-profile .enrollee-top .name-div {
    margin-bottom: 25px;
  }
}
.enrollee-profile .enrollee-top .name-div h4 {
  font-size: 32px;
  color: #333333;
  font-weight: 500;
}
@media only screen and (max-width: 529px) {
  .enrollee-profile .enrollee-top .name-div h4 {
    font-size: 18px;
  }
}
.enrollee-profile .enrollee-top .name-div .status {
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #EDEDEF;
  border-radius: 41px;
  font-weight: 500;
  font-size: 14px;
  color: #75757B;
  padding: 0 15px;
  margin-left: 22px;
  margin-right: 15px;
}
.enrollee-profile .enrollee-top .name-div .status.positive {
  background: #E5454C;
}
.enrollee-profile .enrollee-top .name-div .status.negative {
  background: #14DA9E;
}
.enrollee-profile .enrollee-top .name-div .status.unverified {
  background: #6C62E3;
}
.enrollee-profile .enrollee-top .action-buttons {
  display: flex;
  align-items: center;
  margin-bottom: 50px;
}
@media only screen and (min-width: 530px) and (max-width: 949px) {
  .enrollee-profile .enrollee-top .action-buttons {
    margin-bottom: 30px;
    justify-content: space-between;
  }
}
.enrollee-profile .enrollee-top .action-buttons button {
  height: 45px;
  min-width: 175px;
  box-sizing: border-box;
  border-radius: 6px;
  background: transparent;
  font-weight: 600;
  font-size: 14px;
  margin-right: 22px;
}
.enrollee-profile .enrollee-top .action-buttons button i {
  margin-right: 15px;
}
@media only screen and (min-width: 530px) and (max-width: 949px) {
  .enrollee-profile .enrollee-top .action-buttons button {
    height: 45px;
    min-width: 155px;
    margin-right: 11px;
  }
}
.enrollee-profile .enrollee-top .action-buttons button:last-child {
  margin-right: 0;
}
.enrollee-profile .enrollee-top .action-buttons .divider {
  height: 30px;
  width: 1.5px;
  background: rgba(225, 225, 225, 0.5);
  margin-right: 22px;
}
@media only screen and (min-width: 530px) and (max-width: 949px) {
  .enrollee-profile .enrollee-top .action-buttons .divider {
    margin-right: 11px;
  }
}
.enrollee-profile .enrollee-top .action-buttons button.send-invite {
  background: #19AC55;
  border: 1px solid #19AC55;
  color: #FFFFFF;
}
.enrollee-profile .enrollee-top .action-buttons button.sample-button {
  background: #DCDEE5;
  border: 1px solid #DCDEE5;
  color: #475356;
}
.enrollee-profile .enrollee-top .action-buttons button.test-btn {
  background: #395EE0;
  border: 1px solid #395EE0;
  color: #ffffff;
}
.enrollee-profile .enrollee-top .action-buttons select:active,
.enrollee-profile .enrollee-top .action-buttons select:focus {
  outline: none;
}
.enrollee-profile .enrollee-top .action-buttons .invite-sent {
  background: #19AC55;
  border: 1px solid #19AC55;
  color: #FFFFFF;
  font-weight: 600;
  height: 45px;
  min-width: 175px;
  border-radius: 6px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
  margin-right: 22px;
}
.enrollee-profile .enrollee-top .action-buttons .invite-sent i {
  margin-right: 15px;
}
@media only screen and (min-width: 530px) and (max-width: 949px) {
  .enrollee-profile .enrollee-top .action-buttons .invite-sent {
    margin-right: 11px;
  }
}
.enrollee-profile .enrollee-top .action-buttons .collected {
  background: #14DA9E;
  color: #ffffff;
  font-weight: 600;
  height: 45px;
  min-width: 175px;
  border-radius: 6px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
  margin-right: 22px;
}
.enrollee-profile .enrollee-top .action-buttons .collected i {
  margin-right: 15px;
}
@media only screen and (min-width: 530px) and (max-width: 949px) {
  .enrollee-profile .enrollee-top .action-buttons .collected {
    margin-right: 11px;
  }
}
.enrollee-profile .enrollee-top .progress-tabs {
  display: flex;
}
@media only screen and (min-width: 530px) and (max-width: 949px) {
  .enrollee-profile .enrollee-top .progress-tabs {
    justify-content: space-between;
  }
}
.enrollee-profile .enrollee-top .progress-tabs .tab {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 30px;
  width: 190px;
  height: 45px;
  background: #EDEDEF;
  border-radius: 6px;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #75757B;
}
.enrollee-profile .enrollee-top .progress-tabs .tab i {
  margin-right: 12px;
  font-size: 18px;
}
@media only screen and (min-width: 530px) and (max-width: 949px) {
  .enrollee-profile .enrollee-top .progress-tabs .tab {
    width: auto;
    padding: 0 10px;
    height: 35px;
    font-size: 12px;
    margin-right: 0;
  }
}
.enrollee-profile .enrollee-top .progress-tabs .tab.done {
  background: #EAF7EC;
  color: #4DB660;
}
.enrollee-profile .map-container .main {
  display: block;
}
.enrollee-profile .main {
  display: flex;
}
.enrollee-profile .main .left {
  width: 70%;
  background-color: #FFFFFF;
  border-radius: 4px;
}
.enrollee-profile .main .left .top {
  border-bottom: 0.5px solid rgba(137, 134, 142, 0.22);
  padding: 24px 40px;
  display: flex;
  justify-content: space-between;
}
.enrollee-profile .main .left .top h5 {
  font-size: 18px;
  color: #3A3643;
}
.enrollee-profile .main .left .top .logo {
  display: none;
}
.enrollee-profile .main .left .top button {
  height: 24px;
  border: 0.75px solid #6C62E3;
  box-sizing: border-box;
  border-radius: 4px;
  background: transparent;
  font-size: 12px;
  color: #6C62E3;
}
.enrollee-profile .main .left .details {
  padding: 40px 54px;
}
.enrollee-profile .main .left .details .loader {
  width: 100%;
  display: flex;
  justify-content: center;
}
.enrollee-profile .main .left .details .loader i {
  color: #6C63FF;
  font-size: 46px;
}
.enrollee-profile .main .left .details table {
  width: 100%;
  table-layout: fixed;
  border-spacing: 0;
}
.enrollee-profile .main .left .details table tr td {
  border-bottom: 0.75px solid #E5E4E6;
  padding: 17px 0;
}
.enrollee-profile .main .left .details table tr td h3,
.enrollee-profile .main .left .details table tr td h4 {
  font-size: 12px;
  color: #212B36;
}
.enrollee-profile .main .left .details table tr td h3.yes {
  color: #ffffff;
  padding: 3px 5px;
  border-radius: 2px;
  background: #14DA9E;
  display: inline-block;
}
.enrollee-profile .main .left .details table tr td h3.no {
  color: #ffffff;
  padding: 3px 5px;
  border-radius: 2px;
  background: #E5454C;
  display: inline-block;
}
.enrollee-profile .main .left .details table tr td:first-child {
  width: 40%;
  max-width: 40%;
}
.enrollee-profile .main .left .details table tr td:last-child {
  padding-left: 15px;
}
.enrollee-profile .main .left .details table tr:last-child td {
  border-bottom: 0;
}
.enrollee-profile .main .left .no-border {
  background: #ffffff;
  width: 100%;
  height: 2px;
  margin-top: -1px;
}
.enrollee-profile .main .left .button-div {
  display: flex;
  justify-content: flex-end;
  padding: 0px 55px 25px;
}
.enrollee-profile .main .left .button-div button {
  height: 42px;
  width: 145px;
  background: #919EAB;
  box-shadow: 2px 2px 5px rgba(128, 152, 213, 0.15);
  border-radius: 4px;
  color: #FFFFFF;
  font-size: 14px;
}
@media only screen and (min-width: 530px) and (max-width: 949px) {
  .enrollee-profile .main .left {
    width: 100%;
  }
  .enrollee-profile .main .left .top {
    padding: 15px 40px;
  }
  .enrollee-profile .main .left .details {
    padding: 20px 54px;
  }
}
@media only screen and (max-width: 529px) {
  .enrollee-profile .main .left {
    width: 100%;
  }
  .enrollee-profile .main .left .top {
    padding: 15px 20px;
  }
  .enrollee-profile .main .left .details {
    padding: 20px;
  }
}
.enrollee-profile .main .right {
  margin-left: 30px;
  width: 30%;
}
.enrollee-profile .main .right .closest {
  width: 100%;
  background: #FFFFFF;
  border-radius: 4px;
  padding: 20px;
  margin-bottom: 28px;
  box-sizing: border-box;
}
.enrollee-profile .main .right .closest .item {
  display: flex;
}
.enrollee-profile .main .right .closest .item img {
  margin-right: 20px;
  height: 40px;
  width: 40px;
}
.enrollee-profile .main .right .closest .item h5 {
  color: #5B60D3;
  font-size: 12px;
  margin-bottom: 5px;
}
.enrollee-profile .main .right .closest .item h6 {
  font-size: 12px;
  color: #4D4D4D;
}
.enrollee-profile .main .right .closest .item:first-child {
  padding-bottom: 20px;
  border-bottom: 0.5px solid rgba(137, 134, 142, 0.22);
}
.enrollee-profile .main .right .closest .item:last-child {
  padding-top: 20px;
}
.enrollee-profile .main .right .btn-div {
  width: 100%;
  display: flex;
  justify-content: center;
}
.enrollee-profile .main .right .btn-div button {
  font-size: 14px;
  color: #FFFFFF;
  width: 234px;
  height: 42px;
  background: #6C63FF;
  box-shadow: 2px 2px 5px rgba(128, 152, 213, 0.15);
  border-radius: 4px;
  font-weight: bold;
}
.invite-modal {
  padding: 25px 35px;
}
.invite-modal h4 {
  font-weight: bold;
  font-size: 24px;
  text-align: center;
  letter-spacing: 0.294494px;
  color: #19191D;
}
.invite-modal h5 {
  font-size: 14px;
  line-height: 29px;
  text-align: center;
  letter-spacing: 0.294494px;
  color: #5A6163;
  font-weight: 450;
}
.invite-modal .invite-section {
  display: grid;
  grid-template-columns: 0.6fr 0.4fr;
  grid-column-gap: 40px;
  margin-top: 46px;
}
.invite-modal .invite-section .time-section {
  border: 1.5px solid #F5F6FA;
  border-radius: 4px;
  padding: 30px 26px;
}
.invite-modal .invite-section .time-section p {
  margin-bottom: 15px;
  letter-spacing: 0.294494px;
  font-weight: 450;
  font-size: 20px;
  color: #3B3E45;
}
.invite-modal .invite-section .time-section .time-slots {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
}
.invite-modal .invite-section .time-section .time-slots .slot {
  height: 44px;
  width: 160px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  letter-spacing: 0.22px;
  color: #787885;
  border: 1px solid #B4B4BB;
  box-sizing: border-box;
  border-radius: 10px;
  cursor: pointer;
}
.invite-modal .invite-section .time-section .time-slots .slot.selected {
  background: #2979FF;
  border: 1px solid #2979FF;
  color: #FFFFFF;
}
.invite-modal .invite-section .time-section .time-slots .slot.selected:hover {
  background: #2979FF;
}
.invite-modal .invite-section .time-section .time-slots .slot:hover {
  background: rgba(240, 240, 241, 0.5);
}
.invite-modal .invite-section .date-selected {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.invite-modal .invite-section .date-selected .date-input-div {
  border: 1.5px solid #F5F6FA;
  border-radius: 4px;
  padding: 20px 20px;
  width: 100%;
}
.invite-modal .invite-section .date-selected .date-input-div p {
  font-weight: 450;
  font-size: 20px;
  color: #3B3E45;
  margin-bottom: 10px;
}
.invite-modal .invite-section .date-selected .date-input-div .date-input {
  width: 100%;
  height: 55px;
  background: #F6F6F6;
  border: 1px solid #F6F6F6;
  border-radius: 4px;
  font-weight: 450;
  font-size: 18px;
  padding: 0 24px;
}
.invite-modal .invite-section .date-selected .date-input-div .date-input:focus,
.invite-modal .invite-section .date-selected .date-input-div .date-input:active {
  outline: none;
}
.invite-modal .invite-section .date-selected .date-input-div .react-datepicker-wrapper,
.invite-modal .invite-section .date-selected .date-input-div .react-datepicker__input-container {
  display: block;
}
.invite-modal .invite-section .date-selected .appointment-details {
  padding: 20px 32px;
  background: #F6F7FB;
  border-radius: 4px;
}
.invite-modal .invite-section .date-selected .appointment-details .invite-no {
  font-weight: 500;
  font-size: 14px;
  line-height: 164.22%;
  color: #B620E0;
  background: #F7E7FC;
  border-radius: 50px;
  padding: 3px 10px;
}
.invite-modal .invite-section .date-selected .appointment-details p {
  letter-spacing: 0.294494px;
  color: #3B3E45;
  font-weight: 450;
  font-size: 18px;
  line-height: 29px;
}
.invite-modal .invite-section .date-selected .appointment-details h4 {
  letter-spacing: 0.294494px;
  color: #3B3E45;
  font-weight: bold;
  font-size: 20px;
  line-height: 29px;
  text-align: left;
  margin-bottom: 22px;
}
.invite-modal .invite-section .date-selected .appointment-details .error-text {
  color: #9c2b2e;
  font-size: 12px;
  margin-top: -28px;
  margin-bottom: 0px;
}
.invite-modal .invite-section .date-selected .appointment-details .button-div {
  display: flex;
}
.invite-modal .invite-section .date-selected .appointment-details .button-div button {
  height: 47px;
  width: 150px;
  background: #BBC6CF;
  border: 1px solid #BBC6CF;
  border-radius: 4px;
  letter-spacing: 0.22px;
  color: #333A43;
  font-weight: bold;
  font-size: 14px;
}
.invite-modal .invite-section .date-selected .appointment-details .button-div button.send {
  background: #19AC55;
  border: 1px solid #19AC55;
  color: #FFFFFF;
  margin-left: 24px;
}
.collection-modal {
  padding: 25px 35px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.collection-modal p {
  text-align: center;
  margin-bottom: 20px;
}
.collection-modal i {
  font-size: 40px;
  color: #6C63FF;
  margin-bottom: 15px;
}
.collection-modal .btn-div button {
  font-size: 14px;
  color: #FFFFFF;
  width: 80px;
  height: 42px;
  background: #6C63FF;
  box-shadow: 2px 2px 5px rgba(128, 152, 213, 0.15);
  border-radius: 4px;
  font-weight: bold;
}
.collection-modal .btn-div button.btn-outline {
  background: transparent;
  color: #6C63FF;
  border: 1px solid #6C63FF;
  margin-right: 20px;
}
.collection-modal i {
  margin-top: 15px;
  font-size: 24px;
}
.collection-modal select {
  border: none;
  box-shadow: 0px 0px 10px rgba(219, 218, 227, 0.4);
  border-radius: 4px;
  padding: 11px 13px;
  width: 140px;
  height: 42px;
  font-size: 16px;
  background-color: transparent;
  display: block;
  margin-bottom: 15px;
  background-color: #fff;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iOSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNNy40MTQgNS40OTJMMTIuNDk3LjQ4NGwxLjQ1MiAxLjQzLTYuNTM1IDYuNDQtNi41MzUtNi40NEwyLjMzMS40ODRsNS4wODMgNS4wMDh6IiBmaWxsPSIjNDI0MjQyIi8+PC9zdmc+"), linear-gradient(to bottom, #ffffff 0%, #ffffff 100%);
  background-repeat: no-repeat, repeat;
  background-position: right 10px top 50%, 0 0;
  background-size: 12px auto, 100%;
  margin-bottom: 25px;
  -o-appearance: none;
  -ms-appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.collection-modal select:focus {
  outline: none;
}
.collection-modal .error-text {
  color: #BC3E06;
  text-align: right;
  font-size: 12px;
  margin-top: 10px;
  margin-bottom: 0;
}
@media only screen and (max-width: 529px) {
  .collection-modal .error-text {
    text-align: center;
  }
}
.dashboard-login {
  width: 100%;
  height: 100%;
  display: flex;
  background-color: #F5F6FA;
}
.dashboard-login div {
  flex-grow: 1;
  flex-basis: 0;
}
.dashboard-login .left {
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dashboard-login .left img {
  height: 80px;
  width: auto;
}
.dashboard-login .right {
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
}
.dashboard-login .right .form {
  background: #FFFFFF;
  border-radius: 4px;
  max-width: 400px;
  padding: 50px;
  position: relative;
}
.dashboard-login .right .form .logo-div {
  height: 60px;
  margin-bottom: 20px;
}
.dashboard-login .right .form .logo-div img {
  height: 100%;
  width: auto;
  display: block;
  margin: 0 auto;
}
.dashboard-login .right .form h4 {
  font-size: 20px;
  text-align: center;
  color: #6C62E3;
  margin: 0;
  margin-bottom: 20px;
}
.dashboard-login .right .form p {
  position: absolute;
  bottom: 20px;
  right: 0;
  left: 0;
  text-align: center;
  font-size: 14px;
  color: #BC3E06;
}
.dashboard-login .right .form .reset-text {
  text-align: center;
  margin-bottom: 25px;
}
.dashboard-login .right .form form .input-div {
  width: 100%;
  margin-bottom: 30px;
  height: 69px;
  background: rgba(237, 237, 237, 0.4);
  border: 0.75px solid #DBDAE3;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 7px 15px;
  position: relative;
}
.dashboard-login .right .form form .input-div label {
  width: 100%;
  font-size: 14px;
  color: #212B36;
  font-weight: medium;
}
.dashboard-login .right .form form .input-div input {
  width: 100%;
  font-size: 18px;
  color: #979797;
  background-color: transparent;
  border: none;
  padding-top: 9px;
}
.dashboard-login .right .form form .input-div input:focus {
  outline: none;
}
.dashboard-login .right .form form .input-div span {
  position: absolute;
  top: 8px;
  right: 15px;
  font-size: 12px;
  color: #BC3E06;
}
.dashboard-login .right .form form .input-div.error {
  background: #FFF3EF;
  border: 1px solid #BC3E06;
}
.dashboard-login .right .form form button {
  width: 100%;
  height: 50px;
  background: #6C63FF;
  color: #FFFFFF;
  font-weight: bold;
  font-size: 18px;
  box-shadow: 2px 2px 5px rgba(128, 152, 213, 0.15);
  border-radius: 4px;
  cursor: pointer;
  position: relative;
}
.dashboard-login .right .form form button i {
  position: absolute;
}
.dashboard-login .right .form form button:active,
.dashboard-login .right .form form button:focus {
  outline: none;
}
.dashboard-login .right .form form .forgot-password-link {
  text-align: right;
  display: block;
  cursor: pointer;
  margin-top: -10px;
  margin-bottom: 25px;
  font-weight: 500;
  font-size: 16px;
  color: #276DF7;
}
@media only screen and (min-width: 530px) and (max-width: 949px) {
  .dashboard-login .left {
    display: none;
  }
  .dashboard-login .right .form img {
    margin-bottom: 20px;
    display: block;
    margin-right: auto;
    margin-left: auto;
  }
}
@media only screen and (max-width: 529px) {
  .dashboard-login .left {
    display: none;
  }
  .dashboard-login .right {
    padding: 0 20px;
  }
  .dashboard-login .right .form {
    padding: 50px 20px;
  }
  .dashboard-login .right .form img {
    margin-bottom: 20px;
    display: block;
    margin-right: auto;
    margin-left: auto;
  }
}
