.submission-graph {
  .date-picker,
  .react-datepicker-wrapper {
    background-color: red;
    color: red;
    border-color: red;
    visibility: hidden;
    position: absolute;
    right: 0;
    bottom: 28px;
  }
}

.label-bottom {
  transform: translateY(10px);
}

.fields-datepicker {
  font-weight: 500;
  font-size: 16px;
  color: #9fa2b4;
}
